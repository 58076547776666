.pswp__counter {
    margin-top: 15px;
    margin-left: 20px;
    padding: 0;
    font-size: 14px;
    opacity: 0.95;
    line-height: 30px;
}

.pswp__ui--fit .pswp__top-bar, .pswp__ui--fit .pswp__caption {
    background-color: transparent;
}

.pswp__button {
    opacity: 0.95;
    margin-top: 15px;

    &:first-of-type {
        margin-right: 20px;
    }
}

.pswp__button--arrow--left, .pswp__button--arrow--right {
    transform: translate(0, -50%);

    &::before {
        display: none !important;
    }
}

.pswp__icn {
    fill: #ffffff;
    color: #4f4f4f;
}

.pswp__icn {
    position: absolute;
    top: 14px;
    left: 9px;
    overflow: hidden;
    pointer-events: none;
}

.pswp__button--arrow .pswp__icn {
    top: 50%;
    margin-top: -30px;
    width: 60px;
    height: 60px;
    background: none;
    border-radius: 0;
}

.pswp__button--arrow--right .pswp__icn {
    transform: scale(-1, 1);
    left: auto;
    right: 14px;
}

.pswp__zoom-wrap .pswp__img--placeholder {
    opacity: 0 !important;
    display: block !important;
}